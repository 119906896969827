/**
 * Created by osirvent on 18/11/2015.
 */
angular
    .module('annexaApp')
    .constant('RegModals', {
        diligences: {
            title: 'global.literals.titleDiligencies',
            size: '',
            data: undefined,
            content: [
                '<div ng-if="modal.data.diligences.length == 0" class="text-center cursiva-grey">{{\'global.reg.literals.emptyDiligences\' | translate}}</div>',
                '<table ng-if="modal.data.diligences.length > 0" class="table table-responsive m-a-0">',
                '   <tr>',
                '       <td style="width:10%"><span class="text-muted" translate="global.literals.type">Type</span></td>',
                '       <td style="width:50%"><span class="text-muted" translate="global.literals.profile">Profile</span></td>',
                '       <td style="width:40%"><span class="text-muted" translate="global.literals.state">Status</span></td>',
                '   </tr>',
                '   <tr ng-repeat="dilig in modal.data.diligences">',
                '       <td class="v-middle">{{ dilig.diligenceType[modal.data.langColumn]}}</td>',
                '       <td class="v-middle">{{ dilig.diligenceProfile[modal.data.langColumn]}}</td>',
                '       <td class="v-middle"><span class="label {{dilig.diligenceState.style}}" >{{ \'global.reg.diligenceState.\' + dilig.diligenceState | annexatranslate: app.language}}</span> <span class="text-{{dilig.diligenceState.style}}">{{ dilig.createdDate.time | date:\'dd/MM/yyyy HH:mm\' }}</span></td>',
                '   </tr>',
                '</table>'
            ]
        },
        diligenceComment: {
            title: 'global.literals.newComment',
            size: '',
            icon: 'fa fa-check-square-o',
            data: undefined,
            content: [
                '<div class="row p-t-xs p-x">',
                '   <label class="small m-b-0 _600" translate="global.literals.comments"></label>',
                '</div>',
                '<div class="row p-t-xs p-x" ng-repeat="comment in modal.data.registerEntryDiligenceComments">',
                '   <div class="col-sm-12 b-b p-b-sm m-b-xs">',
                '       <div class="row text-grey">' ,
                '           <div class="col-sm-4">',
                '               <span class="small">{{comment.createdDate | date:\'dd/MM/yyyy HH:mm:ss\' }}</span>',
                '           </div>',
                '           <div class="col-sm-8">',
                '              <span class="small">{{comment.createdUser.name}} {{comment.createdUser.surename1}} {{(comment.createdUser.surename2)? comment.createdUser.surename2 : \'\'}}</span>',
                '           </div>',
                '       </div>',
                '       <div class="row ">' ,
                '           <div class="col-sm-12 pre-line">',
                '               {{comment.comments}} ',
                '           </div>',
                '       </div>',
                '   </div>',
                '</div>',
                '<div class="row p-x-sm">',
                '   <div class="col-xs-10 p-l">',
                '       <textarea rows="3" class="form-control" ng-model="modal.comment" maxlength="5000" placeholder="{{\'global.literals.newComment\' | translate}}"></textarea>',
                '   </div>',
                '   <div class="col-xs-2">',
                '       <button type="button" ng-click="modal.addDiligenceComment()" ng-disabled="!modal.comment" class="btn btn-sm grey inline">',
                '           {{\'global.literals.add\' | translate}}',
                '       </button>',
                '   </div>',
                '</div>'
            ]
        },
        initExpRegisterSelect: {
            title: 'global.literals.initExp',
            size: '',
            icon: 'fa fa-file-text-o',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body ',
                        fieldGroup: [
                            {
                                key: 'familyProcedure',
                                type: 'annexaSelectTreeRow',
                                templateOptions:  {
                                    label: 'global.literals.family',
                                    options: [],
                                    required: false
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'procedure',
                                type: 'annexaSelectRow',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.literals.procedure',
                                    valueProp: 'id',
                                    labelProp: 'language1',
                                    placeholder: '',
                                    options: [],
                                    required: true
                                },controller: ['$scope', '$rootScope', 'RestService', 'Language', function($scope, $rootScope, RestService, Language) {
                                    $scope.clearData = function(data){
                                        angular.forEach(data, function(value, key){
                                            if(value.procedureClassifications){
                                                value.procedureClassifications = undefined;
                                            }
                                            if(value.procedureStartProfiles){
                                                value.procedureStartProfiles = undefined;
                                            }
                                            if(value.procedureViewProfiles){
                                                value.procedureViewProfiles = undefined;
                                            }
                                            if(value.transactionTransitions){
                                                value.transactionTransitions = undefined;
                                            }
                                            if(value.transactions){
                                                value.transactions = undefined;
                                            }
                                        });
                                        return data;
                                    };

                                    $scope.$watchCollection('model.familyProcedure', function(newValue, oldValue, theScope) {
                                        if(newValue !== oldValue) {
                                        	$scope.model[$scope.options.key] = '';
                                            if(newValue.$selected && newValue.$selected.id) {
                                                $scope.to.loading = RestService.filterData('tram', 'Procedure', {family: {id: newValue.$selected.id}}, {
                                                        loggedUser: $rootScope.LoggedUser.id,
                                                        langColumn: Language.getActiveColumn()
                                                    }, 0, 10000, Language.getActiveColumn() + ',asc', 3)
                                                    .then(function (data) {
                                                        var procedures =data.data.content;
                                                        var deletedProcedures = [];
                                                        _.forEach(procedures, function(value){
                                                            var profiles = $linq(value.procedureStartProfiles).intersect($rootScope.LoggedUser.userProfiles, "(x,y) => x.profile.id == y.profile.id").toArray();
                                                            if(!profiles || profiles.length == 0){
                                                                deletedProcedures.push(value);
                                                            }
                                                        });
                                                        procedures = $linq(procedures).except(deletedProcedures, "(x,y) => x.id == y.id").toArray();
                                                        if(procedures && procedures.length > 0) {
                                                            procedures = $scope.clearData(procedures);
                                                            if ($scope.to.entryClassification && $scope.to.entryClassification.procedures && $scope.to.entryClassification.procedures.length > 0) {
                                                                var prodOk = [];
                                                                angular.forEach($scope.to.entryClassification.procedures, function (value, key) {
                                                                    var prodExist = $linq(procedures).where("x => x.id == " + value.id).toArray();
                                                                    if (prodExist.length > 0) {
                                                                        prodOk.push(prodExist[0]);
                                                                    }
                                                                });
                                                                procedures = prodOk;
                                                            }
                                                            $scope.to.options = procedures;
                                                        }else{
                                                            $scope.to.options = $scope.to.procedures;
                                                        }
                                                    });
                                            } else {
                                            	$scope.to.options = $scope.to.procedures;
                                            }
                                        } else {
                                        	$scope.to.options = $scope.to.procedures;
                                        }
                                    });
                                }],
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong'
                                }
                            }
                        ]
                    }
                ]
            },
            submitModal: function () {
            }
        },
        copyRegisterEntryResult: {
            title: 'global.reg.copyRegister.copyRegisterEntry',
            size: '',
            icon: 'fa fa-files-o',
            data: undefined,
            footerClass: 'no-b-t',
            content: [
            	'<div class="col-sm-12"> ',
                '   <p class="m-t">{{\'global.reg.copyRegister.registerEntryCopiesInProcess\' | translate}}</p>',
                '</div>'
            ]
        },
        copyRegisterEntry: {
            title: 'global.reg.copyRegister.copyRegisterEntry',
            size: '',
            icon: 'fa fa-files-o',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body ',
                        fieldGroup: [
                            {
                                key: 'numCopies',
                                type: 'annexaInputRow',
                                className: 'col-sm-12',
                                templateOptions: {
                                    required: true,
                                    type: 'number',
                                    label: 'global.reg.copyRegister.numCopies',
                                    minNumber: 1,
                                    maxlength: 10,
                                    regex: new RegExp(/^[0-9]{1,8}$/)
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'copyInterested',
                                type: 'annexaRadioCheckboxRow',
                                templateOptions: {
                                    type: 'checkbox',
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: '',
                                    valueProp: 'value',
                                    labelProp: 'label',
                                    placeholder: '',
                                    options: [],
                                    required: false
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                },
                                controller: ['$scope', '$filter', '$rootScope', function($scope, $filter,$rootScope) {
                                	$scope.to.options = [{ label: $filter('translate')('global.reg.copyRegister.copyInterested'), value: 1 }];
                                }]
                            },
                            {
                                key: 'copyDocuments',
                                type: 'annexaRadioCheckboxRow',
                                templateOptions: {
                                    type: 'checkbox',
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: '',
                                    valueProp: 'value',
                                    labelProp: 'label',
                                    placeholder: '',
                                    options: [],
                                    required: false
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                },
                                controller: ['$scope', '$filter', '$rootScope', function($scope, $filter,$rootScope) {
                                	$scope.to.options = [{ label: $filter('translate')('global.reg.copyRegister.copyDocuments'), value: 1 }];
                                }]
                            },
                            {
                                key: 'copyDiligences',
                                type: 'annexaRadioCheckboxRow',
                                templateOptions: {
                                    type: 'checkbox',
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: '',
                                    valueProp: 'value',
                                    labelProp: 'label',
                                    placeholder: '',
                                    options: [],
                                    required: false
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                },
                                hideExpression: function ($viewValue, $modelValue, scope) {
                                	return (scope.model.entryType && scope.model.entryType == 'OUTPUT');
                                },
                                controller: ['$scope', '$filter', '$rootScope', function($scope, $filter, $rootScope) {
                                	$scope.to.options = [{ label: $filter('translate')('global.reg.copyRegister.copyDiligences'), value: 1 }];
                                }]
                            },
                            {
                                key: 'copyNotifications',
                                type: 'annexaRadioCheckboxRow',
                                templateOptions: {
                                    type: 'checkbox',
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: '',
                                    valueProp: 'value',
                                    labelProp: 'label',
                                    placeholder: '',
                                    options: [],
                                    required: false
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                },
                                hideExpression: function ($viewValue, $modelValue, scope) {
                                	return (scope.model.entryType && scope.model.entryType == 'INPUT');
                                },
                                watcher:[
                                    {
                                        type: '$watchCollection',
                                        expression: 'model.copyInterested_1',
                                        listener: function(field, newValue, oldValue, scope, stopWatching) {
                                        	if (newValue && scope.model.copyDocuments_1)
                                        		field.templateOptions.disabled = false;
                                        	else 
                                        		field.templateOptions.disabled = true;
                                        }
                                    },
                                    {
                                        type: '$watchCollection',
                                        expression: 'model.copyDocuments_1',
                                        listener: function(field, newValue, oldValue, scope, stopWatching) {
                                        	if (newValue && scope.model.copyInterested_1)
                                        		field.templateOptions.disabled = false;
                                        	else 
                                        		field.templateOptions.disabled = true;
                                        }
                                    }
                                ],
                                controller: ['$scope', '$filter', '$rootScope', function($scope, $filter,$rootScope) {
                                	$scope.to.options = [{ label: $filter('translate')('global.reg.copyRegister.copyNotifications'), value: 1 }];
                                }]
                            },
                            {
                                key: 'copyOther',
                                type: 'annexaRadioCheckboxRow',
                                templateOptions: {
                                    type: 'checkbox',
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: '',
                                    valueProp: 'value',
                                    labelProp: 'label',
                                    placeholder: '',
                                    options: [],
                                    required: false
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                },
                                controller: ['$scope', '$filter', '$rootScope', function($scope, $filter,$rootScope) {
                                	$scope.to.options = [{ label: $filter('translate')('global.reg.copyRegister.copyOther'), value: 1 }];
                                }]
                            },
                            {
                                key: 'copyUserAndProfile',
                                type: 'annexaRadioCheckboxRow',
                                templateOptions: {
                                    type: 'checkbox',
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: '',
                                    valueProp: 'value',
                                    labelProp: 'label',
                                    placeholder: '',
                                    options: [],
                                    required: false
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                },
                                controller: ['$scope', '$filter', '$rootScope', function($scope, $filter,$rootScope) {
                                	$scope.to.options = [{ label: $filter('translate')('global.reg.copyRegister.copyUserAndProfile'), value: 1 }]; 
                                }]
                            },
                            {
                                key: 'copyPresentationDate',
                                type: 'annexaRadioCheckboxRow',
                                templateOptions: {
                                    type: 'checkbox',
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: '',
                                    valueProp: 'value',
                                    labelProp: 'label',
                                    placeholder: '',
                                    options: [],
                                    required: false
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-xs-12',
                                    labelClass: 'label-strong'
                                },
                                controller: ['$scope', '$filter', '$rootScope', function($scope, $filter,$rootScope) {
                                	$scope.to.options = [{ label: $filter('translate')('global.reg.copyRegister.copyPresentationDate'), value: 1 }]; 
                                }]
                            }
                        ]
                    }
                ]
            },
            submitModal: function () {
            	
            }
        }
    });
