/**
 * Created by osirvent on 30/11/2015.
 */
'user strict';
angular
    .module('annexaApp')
    .config(['$stateProvider', function($stateProvider) {
        $stateProvider
            .state('annexa.reg', {
                abstract: true,
                url: '/reg',
                views: {
                    "": {
                        templateUrl: './views/layout/reg.html'
                    }
                },
                resolve: {
                    getDocumentLists: ['DccumentsFactory', function (DccumentsFactory) {
                        return DccumentsFactory.getDocumentLists();
                    }],
                    getTaskLists:['GlobalDataFactory', function (GlobalDataFactory) {
                        return GlobalDataFactory.initializeTaskLists();
                    }],
                    getTerritorytLists: ['TerritoryFactory', function (TerritoryFactory) {
                        return TerritoryFactory.initializeLists();
                    }]
                    
                }
            })
            .state('annexa.reg.input', {
                redirectTo: 'annexa.reg.input.entries',
                url: '/input',
                views: {
                    "mainreg@annexa.reg": {
                        controller: 'RegInputController',
                        templateUrl: './views/layout/reg_input.html'
                    }
                },
                data: {
                    title: 'global.reg.input.toptitle',
                    displayName: 'global.reg.input.toptitle',
                    authenticate: true,
                    permissions: {
                        only: ['view_input_register', 'diligence_input_register','view_all_diligences','view_all_input_register']
                    }
                }
            })
            .state('annexa.reg.input.entries', {
                url: '/entries',
                params: {
                	filterId: { value: null, squash:true }
                },
                views: {
                    "mainreginput@annexa.reg.input": {
                        templateUrl: './views/layout/reg_input_entries.html',
                        controller: 'RegInputEntriesController'
                    }
                },
                data: {
                    displayName: 'global.literals.inputs',
                    authenticate: true,
                    permissions: {
                        only: ['view_input_register','view_all_input_register']
                    }
                }
            })
            .state('annexa.reg.input.diligences', {
                url: '/diligences',
                params: {
                    diligenceType: null,
                    diligenceStatus: null,
					filterId: { value: null, squash:true }
                },
                data: {
                    displayName: 'global.literals.titleDiligencies',
                    permissions: {
                        only: ['diligence_input_register','view_all_diligences']
                    }
                },
                views: {
                    "mainreginput@annexa.reg.input": {
                        controller: 'RegInputDiligencesController',
                        templateUrl: './views/layout/reg_input_diligences.html'
                    }
                }
            })
            .state('annexa.reg.input.diligences.rejected', {
                url: '/diligencesRejected',
                params: {
                    diligenceType: null,
                    diligenceStatus: null,
					filterId: { value: null, squash:true }
                },
                data: {
                    displayName: 'global.literals.titleDiligenciesRejected',
                    permissions: {
                        only: ['view_tab_rejected_diligences']
                    }
                },
                views: {
                    "mainreginput@annexa.reg.input": {
                        controller: 'RegInputDiligencesRejectedController',
                        templateUrl: './views/layout/reg_input_diligences.html'
                    }
                }
            })
            .state('annexa.reg.input.entries.new', {
                url: '/new',
                views: {
                    "mainreginput@annexa.reg.input": {
                        controller: 'RegInputEntriesNewController',
                        templateUrl: './views/layout/reg_input_entries_new.html'
                    }
                },
                data: {
                    displayName: 'global.reg.input.new',
                    authenticate: true,
                    permissions: {
                        only: 'new_input_entry_register'
                    }
                }
            })
            .state('annexa.reg.input.entries.edit', {
                url: '/edit/:input',
                views: {
                    "mainreginput@annexa.reg.input": {
                        controller: 'RegInputEntriesEditController',
                        templateUrl: './views/layout/reg_input_entries_edit.html'
                    }
                },
                data: {
                    displayName: 'global.reg.input.edit',
                    authenticate: true,
                    permissions: {
                        only: ['view_input_register', 'external_view_register_input','view_all_input_register']
                    }
                },
                resolve: {
                    regentry: ['$q', '$state', '$stateParams', 'RegFactory', '$timeout', function($q, $state, $stateParams, RegFactory, $timeout) {
                        if($stateParams.input) {
                            return RegFactory.getRegisterEntry($stateParams.input);
                        } else {
                            $timeout(function() { $state.go('annexa.reg.input.entries'); });
                            return $q.reject("No input registry");
                        }
                    }]
                }
            })
            .state('annexa.reg.input.diligences.edit', {
                url: '/diligenceedit/:input/:diligence',
                views: {
                    "mainreginput@annexa.reg.input": {
                        controller: 'RegInputDiligencesEditController',
                        templateUrl: './views/layout/reg_input_diligences_edit.html'
                    }
                },
                data: {
                    displayName: 'global.reg.input.diligence_edit',
                    authenticate: true,
                    permissions: {
                        only: ['diligence_input_register', 'view_all_diligences']
                    }
                },
                resolve: {
                    regentry: ['$q', '$state', '$stateParams', 'RegFactory', '$timeout', function($q, $state, $stateParams, RegFactory, $timeout) {
                        if($stateParams.input && $stateParams.diligence) {
                            return RegFactory.getReqisterEntryUpdateAccessDiligences($stateParams.input);
                        } else {
                            $timeout(function() { $state.go('annexa.reg.input.regdiligence'); });
                            return $q.reject("No input registry");
                        }
                    }]
                }
            })
            .state('annexa.reg.output', {
                url: '/output',
                redirectTo: 'annexa.reg.output.entries',
                views: {
                    "mainreg@annexa.reg": {
                        controller: 'RegOutputController',
                        templateUrl: './views/layout/reg_output.html'
                    }
                },
                data: {
                    title: 'global.reg.output.toptitle',
                    displayName: 'global.reg.output.toptitle',
                    authenticate: true,
                    permissions: {
                        only: ['view_output_register', 'view_notifications', 'view_all_notifications', 'view_all_output_register']
                    }
                }
            })
            .state('annexa.reg.output.entries', {
                url: '/entries',
                params: {
                	filterId: { value: null, squash:true }
                },
                views: {
                    "mainregoutput@annexa.reg.output": {
                        templateUrl: './views/layout/reg_output_entries.html',
                        controller: 'RegOutputEntriesController'
                    }
                },
                data: {
                    displayName: 'global.literals.outputs',
                    authenticate: true,
                    permissions: {
                        only: ['view_output_register', 'view_all_output_register']
                    }
                }
            })
            .state('annexa.reg.output.notifications', {
                url: '/notifications',
                params: {
					status: { value: null, squash:true },
					filterId: { value: null, squash:true }
                },
                data: {
                    displayName: 'global.literals.notifications',
                    authenticate: true,
                    permissions: {
                        only: ['view_notifications', 'view_all_notifications']
                    }
                },
                views: {
                    "mainregoutput@annexa.reg.output": {
                        controller: 'RegOutputNotificationsController',
                        templateUrl: './views/layout/reg_output_notifications.html'
                    }
                }
            })
            .state('annexa.reg.output.remittances', {
                url: '/remittances',
                params: {
					status: { value: null, squash:true },
					filterId: { value: null, squash:true }
                },
                data: {
                    displayName: 'global.literals.remittances',
                    authenticate: true,
                    permissions: {
                        only: ['view_remittance', 'view_all_remittance']
                    }
                },
                views: {
                    "mainregoutput@annexa.reg.output": {
                        controller: 'RegOutputRemittancesController',
                        templateUrl: './views/layout/reg_output_remittances.html'
                    }
                }
            })
            .state('annexa.reg.output.remittances.new', {
                url: '/new/:remittancesId',
                views: {
                    "mainregoutput@annexa.reg.output": {
                        controller: 'RegOutputRemittanceNewController',
                        templateUrl: './views/layout/reg_output_remittances_new.html'
                    }
                },
                data: {
                    displayName: 'global.remittance.literals.newRemittance',
                    authenticate: true,
                    permissions: {
                        only: ['manage_remittance', 'manage_all_remittance']
                    }
                }
            })
            .state('annexa.reg.output.remittances.edit', {
                url: '/edit/:remittance',
                views: {
                    "mainregoutput@annexa.reg.output": {
                        controller: 'RegOutputRemittanceEditController',
                        templateUrl: './views/layout/reg_output_remittances_edit.html'
                    }
                },
                data: {
                    displayName: 'global.remittance.literals.editRemittance',
                    authenticate: true,
                    permissions: {
                        only: ['view_remittance']
                    }
                },
                resolve: {
                    regentry: ['$q', '$state', '$stateParams', 'RegFactory', '$timeout', function ($q, $state, $stateParams, RegFactory, $timeout) {
                        if ($stateParams.remittance) {
                            return RegFactory.getRemittance($stateParams.remittance);
                        } else {
                            $timeout(function () {
                                $state.go('annexa.reg.output.remittances');
                            });
                            return $q.reject("No remittance");
                        }
                    }]
                }
            })
            .state('annexa.reg.output.entries.new', {
                url: '/new/:registerEntryId',
                views: {
                    "mainregoutput@annexa.reg.output": {
                        controller: 'RegOutputEntriesNewController',
                        templateUrl: './views/layout/reg_output_entries_new.html'
                    }
                },
                data: {
                    displayName: 'global.reg.output.new',
                    authenticate: true,
                    permissions: {
                        only: 'new_output_entry_register'
                    }
                }
            })
            .state('annexa.reg.output.entries.edit', {
                url: '/edit/:output',
                views: {
                    "mainregoutput@annexa.reg.output": {
                        controller: 'RegOutputEntriesEditController',
                        templateUrl: './views/layout/reg_output_entries_edit.html'
                    }
                },
                data: {
                    displayName: 'global.reg.output.edit',
                    authenticate: true,
                    permissions: {
                        only: ['view_output_register', 'external_view_register_output', 'view_all_output_register']
                    }
                },
                resolve: {
                    regentry: ['$q', '$state', '$stateParams', 'RegFactory', '$timeout', function ($q, $state, $stateParams, RegFactory, $timeout) {
                        if ($stateParams.output) {
                            return RegFactory.getRegisterEntry($stateParams.output);
                        } else {
                            $timeout(function () {
                                $state.go('annexa.reg.output.entries');
                            });
                            return $q.reject("No output registry");
                        }
                    }]
                }
            })
            .state('annexa.reg.view', {
            	url: '/view/:csv',
                views: {
                    "mainreg@annexa.reg": {
                        controller: 'RegViewEntriesController',
                        templateUrl: './views/layout/reg_view_entries.html'
                    }
                },
                data: {
                    title: 'global.reg.view',
                    displayName: 'global.reg.view',
                    authenticate: true,
                    permissions: {
                        only: ['view_input_register', 'external_view_register_input','view_all_input_register', 'view_output_register', 'external_view_register_output', 'view_all_output_register']
                    }
                },
                resolve: {
                    regentry: ['$q', '$state', '$stateParams', 'RegFactory', '$timeout', function ($q, $state, $stateParams, RegFactory, $timeout) {
                        if ($stateParams.csv) {
                            return RegFactory.getReqisterEntryByCsv($stateParams.csv);
                        } else {
                            $timeout(function () {
                                $state.go('annexa.dashboard');
                            });
                            return $q.reject("No output registry");
                        }
                    }]
                }
            })
            .state('annexa.reg.viewInput', {
                url: '/inputView/:csv',
                views: {
                    "mainreg@annexa.reg": {
                        controller: 'RegInputEntriesEditController',
                        templateUrl: './views/layout/reg_input_entries_edit.html'
                    }
                },
                data: {
                	title: 'global.reg.view',
                    displayName: 'global.reg.view',
                    authenticate: true,
                    permissions: {
                        only: ['view_input_register', 'external_view_register_input','view_all_input_register']
                    }
                },
                resolve: {
                    regentry: ['$q', '$state', '$stateParams', 'RegFactory', '$timeout', function ($q, $state, $stateParams, RegFactory, $timeout) {
                        if ($stateParams.csv && RegFactory.regEntry && RegFactory.regEntry.entryType == 'INPUT' && RegFactory.regEntry.csv && RegFactory.regEntry.csv == $stateParams.csv) {
                            return  $q.resolve("Ok");
                        } else {
                        	if($stateParams.csv && (!RegFactory.regEntry || !RegFactory.regEntry.id)){
                        		return RegFactory.getReqisterEntryByCsvAndType($stateParams.csv, 'INPUT');
                        	}else{
	                            $timeout(function () {
	                                $state.go('annexa.dashboard');
	                            });
	                            return $q.reject("No output registry");
                        	}
                        }
                    }]
                }
            })
            .state('annexa.reg.viewOutput', {
                url: '/outputView/:csv',
                views: {
                    "mainreg@annexa.reg": {
                        controller: 'RegOutputEntriesEditController',
                        templateUrl: './views/layout/reg_output_entries_edit.html'
                    }
                },
                data: {
                	title: 'global.reg.view',
                	displayName: 'global.reg.view',
                    authenticate: true,
                    permissions: {
                        only: ['view_output_register', 'external_view_register_output', 'view_all_output_register']
                    }
                },
                resolve: {
                    regentry: ['$q', '$state', '$stateParams', 'RegFactory', '$timeout', function ($q, $state, $stateParams, RegFactory, $timeout) {
                        if ($stateParams.csv && RegFactory.regEntry && RegFactory.regEntry.entryType == 'OUTPUT' && RegFactory.regEntry.csv && RegFactory.regEntry.csv == $stateParams.csv) {
                            return  $q.resolve("Ok");
                        } else {
                        	if($stateParams.csv && (!RegFactory.regEntry || !RegFactory.regEntry.id)){
                        		return RegFactory.getReqisterEntryByCsvAndType($stateParams.csv, 'OUTPUT');
                        	}else{
	                            $timeout(function () {
	                                $state.go('annexa.dashboard');
	                            });
	                            return $q.reject("No output registry");
                        	}
                        }
                    }]
                }
            }).state('annexa.reg.viewByNumber', {
            	url: '/view/number/:number',
                views: {
                    "mainreg@annexa.reg": {
                        controller: 'RegViewEntriesByNumberController',
                        templateUrl: './views/layout/reg_view_entries.html'
                    }
                },
                data: {
                    title: 'global.reg.viewByNumber',
                    displayName: 'global.reg.viewByNumber',
                    authenticate: true,
                    permissions: {
                        only: ['view_input_register', 'external_view_register_input','view_all_input_register', 'view_output_register', 'external_view_register_output', 'view_all_output_register']
                    }
                },
                resolve: {
                    regentry: ['$q', '$state', '$stateParams', 'RegFactory', '$timeout', function ($q, $state, $stateParams, RegFactory, $timeout) {
                        if ($stateParams.number) {
                            return RegFactory.getReqisterEntryByNumber($stateParams.number);
                        } else {
                            $timeout(function () {
                                $state.go('annexa.dashboard');
                            });
                            return $q.reject("No output registry");
                        }
                    }]
                }
            })
            .state('annexa.reg.viewByNumberInput', {
                url: '/inputView/number/:number',
                views: {
                    "mainreg@annexa.reg": {
                        controller: 'RegInputEntriesEditController',
                        templateUrl: './views/layout/reg_input_entries_edit.html'
                    }
                },
                data: {
                	title: 'global.reg.viewByNumber',
                    displayName: 'global.reg.viewByNumber',
                    authenticate: true,
                    permissions: {
                        only: ['view_input_register', 'external_view_register_input','view_all_input_register']
                    }
                },
                resolve: {
                    regentry: ['$q', '$state', '$stateParams', 'RegFactory', '$timeout', function ($q, $state, $stateParams, RegFactory, $timeout) {
                        if ($stateParams.number && RegFactory.regEntry && RegFactory.regEntry.entryType == 'INPUT' && RegFactory.regEntry.entryNumber && RegFactory.regEntry.entryNumber == $stateParams.number) {
                            return  $q.resolve("Ok");
                        } else {
                        	if($stateParams.number){
                        		return RegFactory.getReqisterEntryByNumberAndType($stateParams.number, 'INPUT');
                        	}else{
	                            $timeout(function () {
	                                $state.go('annexa.dashboard');
	                            });
	                            return $q.reject("No output registry");
                        	}
                        }
                    }]
                }
            })
            .state('annexa.reg.viewByNumberOutput', {
                url: '/outputView/number/:number',
                views: {
                    "mainreg@annexa.reg": {
                        controller: 'RegOutputEntriesEditController',
                        templateUrl: './views/layout/reg_output_entries_edit.html'
                    }
                },
                data: {
                	title: 'global.reg.viewByNumber',
                	displayName: 'global.reg.viewByNumber',
                    authenticate: true,
                    permissions: {
                        only: ['view_output_register', 'external_view_register_output', 'view_all_output_register']
                    }
                },
                resolve: {
                    regentry: ['$q', '$state', '$stateParams', 'RegFactory', '$timeout', function ($q, $state, $stateParams, RegFactory, $timeout) {
                        if ($stateParams.number && RegFactory.regEntry && RegFactory.regEntry.entryType == 'OUTPUT' && RegFactory.regEntry.entryNumber && RegFactory.regEntry.entryNumber == $stateParams.number) {
                            return  $q.resolve("Ok");
                        } else {
                        	if($stateParams.number){
                        		return RegFactory.getReqisterEntryByNumberAndType($stateParams.number, 'OUTPUT');
                        	}else{
	                            $timeout(function () {
	                                $state.go('annexa.dashboard');
	                            });
	                            return $q.reject("No output registry");
                        	}
                        }
                    }]
                }
            });
    }]);