/**
 * Created by osirvent on 06/09/2016.
 */
angular
    .module('annexaApp')
    .factory('RemittanceFactory',['$q', '$http', '$filter', 'AnnexaModalFactory','globalModals','$rootScope', 'ErrorFactory', 'AnnexaFormlyFactory', 'Upload', 'DccumentsFactory', 'ThirdFactory', 'SecFactory','Language', 'CommonService', 'RestService', 'GlobalDataFactory', 'DialogsFactory', function($q, $http, $filter, AnnexaModalFactory, globalModals, $rootScope, ErrorFactory, AnnexaFormlyFactory, Upload, DccumentsFactory, ThirdFactory, SecFactory, Language, CommonService, RestService, GlobalDataFactory, DialogsFactory) {

        var factory = {};

        //region Globals
        factory.remittanceGlobalStatus = [
            {id: 'OPEN', name: $filter('translate')('global.remittance.status.OPEN'), class: 'text-info', icon: 'circle', style: 'text-info'},
            {id: 'CLOSE', name: $filter('translate')('global.remittance.status.CLOSE'), class: 'text-warn', icon: 'circle', style: 'text-warn'},
            {id: 'COMPLETE', name: $filter('translate')('global.remittance.status.COMPLETE'), class: 'text-success', icon: 'circle', style: 'text-success'}
        ];
        //endregion
        
        //region Remittance
        factory.createRemittance = function(remittance) {
            var deferred = $q.defer();

            $http({
                url: './api/reg/remittance',
                method: 'POST',
                data: JSOG.encode(remittance)
            }).then(function(data) {
            	deferred.resolve(JSOG.decode(data.data));
            }).catch(function(error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }
        
        factory.deleteRemittance = function(remittanceId) {
            var deferred = $q.defer();

            $http({
                url: './api/reg/remittance/delete/' + remittanceId,
                method: 'DELETE'
            }).then(function(data) {
                deferred.resolve(JSOG.decode(data.data));
            }).catch(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }
        
        factory.closeRemittance = function(remittanceId) {
            var deferred = $q.defer();

            $http({
                url: './api/reg/remittance/close/' + remittanceId,
                method: 'POST'
            }).then(function(data) {
                deferred.resolve(JSOG.decode(data.data));
            }).catch(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }
        
        factory.endRemittance = function(remittanceId) {
            var deferred = $q.defer();

            $http({
                url: './api/reg/remittance/end/' + remittanceId,
                method: 'POST'
            }).then(function(data) {
                deferred.resolve(JSOG.decode(data.data));
            }).catch(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }
        
        factory.generateElectronicFile = function(remittanceId) {
            var deferred = $q.defer();

            $http({
                url: './api/reg/remittance/generateElectronicFile/' + remittanceId,
                method: 'POST'
            }).then(function(data) {
                deferred.resolve(JSOG.decode(data.data));
            }).catch(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }
        
        factory.processRemittances = function() {
            var deferred = $q.defer();

            $http({
                url: './api/reg/remittance/processRemittances',
                method: 'POST'
            }).then(function(data) {
                deferred.resolve(JSOG.decode(data.data));
            }).catch(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }
        
        factory.updateStatus = function() {
            var deferred = $q.defer();

            $http({
                url: './api/reg/remittance/updateStatus',
                method: 'POST'
            }).then(function(data) {
                deferred.resolve(JSOG.decode(data.data));
            }).catch(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }
        
        factory.printNotificationsDocument = function(remittanceId) {
            var deferred = $q.defer();
            
            var translations = {
                    "third_relation_interested": $filter('translate')('global.literals.interested'),
                    "third_relation_representative": $filter('translate')('global.literals.representative'),
                    "yes": $filter('translate')('global.literals.yes'),
                    "no": $filter('translate')('global.literals.no')
                }

            $http({
                method: 'POST',
                url: './api/reg/remittance/printNotificationsDocument/' + remittanceId,
                data: translations
            }).then(function(data) {
                deferred.resolve(data.data.url);
            }).catch(function(error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }
        
        factory.printNotificationsListDocument = function(remittanceId) {
            var deferred = $q.defer();

            var translations = {
                "third_relation_interested": $filter('translate')('global.literals.interested'),
                "third_relation_representative": $filter('translate')('global.literals.representative'),
                "yes": $filter('translate')('global.literals.yes'),
                "no": $filter('translate')('global.literals.no')
            }
            
            $http({
                method: 'POST',
                url: './api/reg/remittance/printNotificationsListDocument/' + remittanceId,
                data: translations
            }).then(function(data) {
                deferred.resolve(data.data.url);
            }).catch(function(error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }
        
        //endRegion
        
        //Region Remittance Notifications
        
        factory.addMultipleNotifications = function(notifications, remittanceId) {
            var deferred = $q.defer();

            $http({
                url: './api/reg/remittance_notifications/addMultiple/' + remittanceId,
                method: 'POST',
                data: JSOG.encode(notifications)
            }).then(function(data) {
                deferred.resolve(JSOG.decode(data.data));
            }).catch(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }
        
        factory.deleteNotification = function(notificationId) {
            var deferred = $q.defer();

            $http({
                url: './api/reg/remittance_notifications/delete/' + notificationId,
                method: 'DELETE'
            }).then(function(data) {
                deferred.resolve(JSOG.decode(data.data));
            }).catch(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }
        
        factory.printNotificationDocument = function(notificationId) {
            var deferred = $q.defer();

            var translations = {
                "third_relation_interested": $filter('translate')('global.literals.interested'),
                "third_relation_representative": $filter('translate')('global.literals.representative'),
                "yes": $filter('translate')('global.literals.yes'),
                "no": $filter('translate')('global.literals.no')
            }
            
            $http({
                method: 'POST',
                url: './api/reg/remittance_notifications/printNotificationDocument/' + notificationId,
                data: translations
            }).then(function(data) {
                deferred.resolve(data.data.url);
            }).catch(function(error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }
        //endRegion

        return factory;
    }]);